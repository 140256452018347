<template>
  <div
      class="video-tile"
      :class="[{ 'full-width': this.fullWidthTile }, `${alignment}`]">
    <ZoomContainer
        ref="zoomContainer"
        class="loading">
      <LazyImage
          :image-source="animationImage.url"
          :alt-text="animationImage.title"
          :container="pageContainer"
          :load="true"
          :ready-to-show="readyToShow"
          @loaded="$emit('loaded')"
          @imageShowing="removeLoadingClass"
          @click="showGallery(videoTile.id)"/>
    </ZoomContainer>
  </div>
</template>

<script>
import LazyImage from '@/components/ui/LazyImage'
import ZoomContainer from '@/components/ui/ZoomContainer'
import {mapActions} from 'vuex'

export default {
  name: 'VideoTile',
  components: {LazyImage, ZoomContainer},
  props: {
    videoTile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      readyToShow: false
    }
  },
  computed: {
    animationImage() {
      return this.videoTile.imageAnimation[0]
    },
    fullWidthTile() {
      return this.videoTile.fullWidthTile
    },
    alignment() {
      return this.videoTile.alignment || ''
    },
    pageContainer() {
      return document.querySelector('#app > .page')
    }
  },
  mounted() {
    setTimeout(() => {
      this.readyToShow = true
    }, 200)
  },
  methods: {
    ...mapActions('gallery', {
      toggleGalleryShow: 'toggleGalleryShow',
      activateGalleryId: 'activateGalleryId'
    }),
    removeLoadingClass() {
      this.$refs.zoomContainer.$el.classList.remove('loading')
    },
    showGallery(tileId) {
      this.toggleGalleryShow()
      this.activateGalleryId(tileId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-tile {
    margin-bottom: 30px;
    &.full-width {
      @apply w-full;
    }

    &:not(.full-width) {
      @apply w-full;
      &.left {
        padding-right: 0;
      }
      &.right {
        padding-left: 0;
      }
    }

    img {
      @apply w-full;
    }

    /* TABLET VIEW */
    @media screen and (min-width: 768px) {
      &:not(.full-width) {
        @apply w-1/2;
        &.left {
          padding-right: 15px;
        }
        &.right {
          padding-left: 15px;
        }
      }
    }

  }
</style>