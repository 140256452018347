<template>
  <div class="video-container">
    <VideoTile
        v-for="(videoTile, index) in videoTilesOrganized"
        :key="index"
        :video-tile="videoTile"
        @loaded="loadedCount+=1" />
  </div>
</template>

<script>
import VideoTile from '@/components/video/VideoTile'

export default {
  name: 'VideoContainer',
  components: {VideoTile},
  props: {
    videoTiles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      loadedCount: 0
    }
  },
  computed: {
    videoTilesOrganized() {
      let nextAlignment = 'left'
      return this.videoTiles.map(tile => {
        if (tile.fullWidthTile) {
          nextAlignment = 'left'
          return tile
        }

        tile.alignment = nextAlignment
        nextAlignment = nextAlignment === 'left' ? 'right' : 'left'

        return tile
      })
    }
  },
  watch: {
    loadedCount(newVal) {
      if (newVal === this.videoTiles.length) {
        this.$emit('loaded')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  @apply flex;
  @apply flex-wrap;
  @apply justify-center;
  max-width: 1060px;
  margin: 0 auto 134px auto;
}
</style>