<template>
  <div class="video-page">
    <PageTitle v-if="showTitle" :title="title" />
    <VideoContainer
        :video-tiles="videoTiles"
        @loaded="toggleShowPage"/>
    <GalleryContainer :gallery-assets="galleryAssets"/>
    <Footer/>
  </div>
</template>

<script>
import api from '@/api'
import PageTitle from '@/components/ui/PageTitle'
import VideoContainer from '@/components/video/VideoContainer'
import GalleryContainer from '@/components/gallery/GalleryContainer';
import Footer from '@/components/footer/Footer'
import {mapActions} from 'vuex'

export default {
  name: 'Video',
  components: {PageTitle, GalleryContainer, VideoContainer, Footer},
  data() {
    return {
      title: '',
      showTitle: false,
      videoTiles: [],
    }
  },
  computed: {
    galleryAssets() {
      return this.videoTiles.map(tile => {
            return this.tileToGalleryVideo(tile)
          })
    }
  },
  mounted() {
    this.loadVideoPage()
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    }),
    loadVideoPage() {

      // Load response from cache first.
      let entry = JSON.parse(localStorage.getItem('video'))

      if (entry) {
        this.updatePageTitle(entry.seo.title)
        this.setVideoFromEntry(entry)
      }

      api.Video.loadVideo().then(response => response.data.data.entry)
        .then(response => {
          entry = response
          localStorage.setItem('video', JSON.stringify(entry))
          this.updatePageTitle(entry.seo.title)
          this.setVideoFromEntry(entry)
        }).catch(() => {
          this.updatePageTitle(entry.seo.title)
          this.setVideoFromEntry(entry)
        })
    },
    setVideoFromEntry(entry) {
      this.title = entry.title
      this.showTitle = entry.showTitle
      this.videoTiles = entry.videoContainer
    },
    tileToGalleryVideo(tile) {
      return {
        id: tile.id,
        placeholder: {
          src: tile.imageAnimation[0].url,
          title: tile.imageAnimation[0].title
        },
        galleryVideo: {
          vimeoVideoId: tile.vimeoVideoId
        }
      }
    },
  }
}
</script>
